import React from 'react'
import Layout from '../components/layout'
import Meta from '../components/meta'
import { graphql } from 'gatsby'
import filterLocale from '../components/utils/filter-locale'
import JobsHero from '../components/jobs-components/jobs-hero'
import JobList from '../components/jobs-components/job-list'

const Jobs = ({ data: { jobs, joblist } }) => {
    const locale = 'en-AU'
	const [{ node }] = filterLocale(jobs.edges, locale)
    
    const {
        seoTitle,
        seoKeywords,
        seoDescription
    } = node 

    return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
			/>
            <JobsHero />
            <JobList joblist={joblist}/>
		</Layout>
    )
}

export const JobsQuery = graphql`
	query JobsQuery {
		jobs:allContentfulPage(filter: {contentful_id: {eq: "1en5Sz2GePhqcISTcB9lCj"}}) {
            edges {
              node {
                seoTitle
                seoKeywords
                seoDescription
                node_locale
              }
            }
		}
        joblist:allContentfulJobs(filter: {node_locale: {eq: "en-AU"}}, sort: {order: ASC, fields: createdAt}) {
            edges {
                node {
                    jobTitle
                    jobDescription {
                        json
                    }
                    branch
                    node_locale
                }
            }
        }
	}
`

export default Jobs
