import React, { useState, useEffect } from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ChevronDown } from 'react-feather'
import { useSpring } from 'react-spring'
import {
    JobListSection,
    JobListContainer,
    AccordionContainer,
    AccordionObject,
    AccordionTitle,
    AccordionContent,
    Paragraph,
    HyperLink,
    List,
    ListItem,
} from './styles'

const JobList = ({ joblist }) => {
    const [jobs, setJobs] = useState([])
    const [indices, setIndices] = useState([0]);

    function toggleItem(toggledIndex) {
        if (indices.includes(toggledIndex)) {
          setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex));
        } else {
          setIndices([...indices, toggledIndex].sort());
        }
    }

    useEffect(() => {
        const newJob = joblist.edges.map((job, i) => {
            return {...job, isOpen: i === 0 ? true: false}
        })
        setJobs(newJob)
    }, [])

    return (
        <JobListSection>
            <JobListContainer>
                <AccordionContainer collapsible multiple index={indices} onChange={toggleItem}>
                    {
                        jobs.map((job, i) => {
                            return <AccordionContents job={job} key={i}/>
                        })
                    }
                </AccordionContainer>
            </JobListContainer>
        </JobListSection>
    )
}

const AccordionContents = ({ job }) => {
    const [isOpen, toggle] = useState(job.isOpen)
    const clickHandler = () => {
        toggle(!isOpen)
    }
    const props = useSpring({
        from: { height: 0, opacity: 0, transform: 'translate3d(-5px,0,0)' },
        to: {
            height: isOpen ? 'auto' : 0,
            opacity: isOpen ? 1 : 0,
            transform: `translate3d(${isOpen ? 0 : 5}px,0,0)`,
        }
    })

    const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => {
				return <Paragraph>{children}</Paragraph>
			},
			[INLINES.HYPERLINK]: (node, children) => {
				return(
					<HyperLink href={node?.data?.uri} target="_blank">{children}</HyperLink>
				)
			},
			[BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
			[BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
		},
	}

    return (
        <AccordionObject>
            <AccordionTitle onClick={() => clickHandler()}>
                <p>{job.node.jobTitle} - {job.node.branch.map((branch, i) => <span>{branch}{job.node.branch.length - 1 !== i ? ', ': ''}</span>)}</p>
                <ChevronDown width="30" height="30" className={isOpen ? 'up' : 'down'}/>
            </AccordionTitle>
            <AccordionContent style={props} className="content">{documentToReactComponents(job.node.jobDescription.json, options)}</AccordionContent>
        </AccordionObject>
    )
}

export default JobList
