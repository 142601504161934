import tw, { css, styled } from 'twin.macro'
import { animated } from 'react-spring'
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
} from '@reach/accordion'
import '@reach/accordion/styles.css'
import { Link } from 'gatsby'

export const JobsSection = styled.section`
	height: 200px;
	max-height: 200px;

	@media (max-width: 767px) {
		height: 150px;
		max-height: 150px;
	}
	background: linear-gradient(
		to right,
		#1b7db3 0%,
		#2e8fb1 25%,
		#58c8df 70%,
		#83d2e4 100%
	);
`
export const JobsContainer = styled.div`
	${tw`flex flex-col w-full items-center justify-center h-full px-6`}
`
export const JobsHeading = styled.h1`
	${tw`text-4xl md:text-5xl text-white font-bold font-display text-center`}
`
export const JobListSection = tw.section``
export const JobListContainer = tw.div``

export const AccordionContainer = styled(Accordion)`
	${tw`w-95 lg:w-9/12 xl:w-8/12 xxl:w-7/12 mx-auto my-8`}
`
export const AccordionObject = styled(AccordionItem)`
	&:not(:last-of-type) {
		${tw`mb-4`}
	}

	> .content {
		${tw`p-4 border border-t-0`}
	}
`
export const AccordionTitle = styled(AccordionButton)`
	${tw`font-bold p-4 bg-gray-200 w-full text-left font-display md:text-xl flex flex-row justify-between relative hover:bg-gray-300`}

	svg {
		${tw`absolute right-0 cursor-pointer`}
		transition: all 0.3s linear;
		top: 25%;
		right: 10px;

		@media (max-width: 767px) {
			width: 23px;
			height: 23px;
		}
	}

	svg.up {
		transform: rotate(180deg);
	}

	svg.down {
		transform: rotate(0);
	}

	&:focus:focus {
		box-shadow: none;
	}
`
export const AccordionContent = animated(AccordionPanel)
export const Paragraph = tw.p`font-display leading-snug`
export const HyperLink = tw.a`underline text-blue-500 font-display hover:no-underline`
export const List = tw.ul``
export const ListItem = tw.li`pl-2 list-disc`
